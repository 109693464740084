import React, { useState, useEffect } from "react";
import bnctextlogo from "../assets/img/bnctext-logo.webp";
import instagramimg from "../assets/social/instagram.png";
import twitterimg from "../assets/social/twitter.png";
import facebookimg from "../assets/social/facebook.png";
import linkedinimg from "../assets/social/linkedin.png";
import youtubeimg from "../assets/social/youtube.png";
import ximg from "../assets/social/x.png";
import "../assets/style/globalStyle.scss";
import styled from "styled-components";

export function useMedia(query) {
  const [matches, setMatches] = useState(false);

  useEffect(() => {
    const media = window.matchMedia(query);
    if (media.matches !== matches) {
      setMatches(media.matches);
    }
    const listener = () => {
      setMatches(media.matches);
    };
    media.addEventListener("change", listener);
    return () => media.removeEventListener("change", listener);
  }, [matches, query]);

  return matches;
}

const NavBar = () => {
  const [isActive, setisActive] = useState(false);

  const isMobile = useMedia("(max-width: 1224px)");

  const HoverText = styled.p`
    color: #000;
    font-weight: 500;
    font-size: 18px;
    :hover {
      color: #aa6280;
      cursor: pointer;
      font-weight: 800;
      margin-right: -4px;
    }
  `;

  const HoverSocial = styled.p`
    width: 20px;
    height: 20px;
    :hover {
      height: 21px;
      width: 21px;
    }
  `;

  const styles = {
    navTitles: {
      marginLeft: 35,
    },
    contactBtn: {
      fontSize: "14px",
      fontWeight: 700,
      color: "white",
      backgroundImage: `linear-gradient(90deg, #D85E4B, #A15477)`,
      borderRadius: 25,
      paddingLeft: 30,
      paddingRight: 30,
      alignItems: "center",
      marginLeft: 30,
      paddingTop: 14,
      paddingBottom: 14,
    },
    bncLogo: {
      width: "82px",
      marginLeft: 60,
      marginTop: 10,
    },
    bncLogoMobile: {
      width: "82px",
      marginLeft: 20,
      marginTop: 10,
    },
  };

  return (
    <nav
      className="navbar is-fixed-top"
      style={{
        flex: "1",
        alignItems: "center",
        fontFamily: "Montserrat",
      }}
    >
      <div
        className="navbar-brand"
        style={{
          width: "100%",
          height: 73,
          boxShadow: "0px 4px 14px rgba(0, 0, 0, 0.1)",
          border: "1px",
        }}
      >
        <a href="/" style={!isMobile ? styles.bncLogo : styles.bncLogoMobile}>
          <img src={bnctextlogo} alt="logo"></img>
        </a>
        <a
          role="button"
          // href="/"
          className={`navbar-burger burger ${isActive ? "is-active" : ""}`}
          data-target="navMenu"
          aria-label="menu"
          aria-expanded="false"
          onClick={() => setisActive(!isActive)}
          tabIndex={0}
          onKeyDown={() => setisActive(!isActive)}
          style={{ alignSelf: "center", color: "#AA6280", marginRight: 20 }}
        >
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
        </a>
        <div
          className={`navbar-menu ${!isActive ? "" : ""}`}
          style={{
            alignSelf: "center",
            alignItems: "end",
          }}
        >
          {/* <a href="/" style={styles.navTitles}>
            <HoverText>Accueil</HoverText>
          </a>
          <a href="/bnCoachingPage" style={styles.navTitles}>
            <HoverText>Ball'N Coaching</HoverText>
          </a>{" "}
          <a href="/agencePage" style={styles.navTitles}>
            <HoverText>L'agence</HoverText>
          </a>
          <a href="/wikiPage" style={styles.navTitles}>
            <HoverText>Wiki</HoverText>
          </a> */}
          <a href="/" style={{ ...styles.navTitles }}>
            <HoverText>Accueil</HoverText>
          </a>
          <a href="/#faq" style={{ ...styles.navTitles }}>
            <HoverText>FAQ</HoverText>
          </a>
          <a href="/blog-list" style={styles.navTitles}>
            <HoverText>Blog</HoverText>
          </a>
          <a href="/eventPage" style={{ ...styles.navTitles }}>
            <HoverText>Évènements</HoverText>
          </a>
          <a href="https://ballncoaching.com/" style={{ ...styles.navTitles }}>
            <HoverText>Ball'N Coaching</HoverText>
          </a>
          {/* <a href="/app/login" style={styles.navTitles}>
            <HoverText>Admin</HoverText>
          </a> */}
        </div>
        <div
          className={`navbar-menu ${!isActive ? "" : ""}`}
          style={{
            alignSelf: "center",
            alignItems: "center",
            justifyContent: "end",
            marginRight: 60,
          }}
        >
          {!isMobile && (
            <>
              <a href="https://www.instagram.com/ballnconnect/">
                <HoverSocial>
                  <img src={instagramimg} alt="logo"></img>
                </HoverSocial>
              </a>
              <a
                href="https://twitter.com/ballnconnect"
                style={{ marginLeft: 20 }}
              >
                <HoverSocial>
                  <img src={ximg} alt="logo"></img>
                </HoverSocial>
              </a>
              <a
                href="https://www.youtube.com/@ballnconnect2451"
                style={{ marginLeft: 20 }}
              >
                <HoverSocial>
                  <img src={youtubeimg} alt="logo"></img>
                </HoverSocial>
              </a>
              <a
                href="https://www.facebook.com/ballnconnect/"
                style={{ marginLeft: 20 }}
              >
                <HoverSocial>
                  <img src={facebookimg} alt="logo"></img>
                </HoverSocial>
              </a>
              <a
                href="https://www.linkedin.com/company/ball-n-connect/"
                style={{ marginLeft: 20 }}
              >
                <HoverSocial>
                  <img src={linkedinimg} alt="logo"></img>
                </HoverSocial>
              </a>
            </>
          )}

          <a href="mailto:contact@ballnconnect.com" style={styles.contactBtn}>
            NOUS CONTACTER
          </a>
        </div>
      </div>
      <div
        className={`navbar-menu ${isActive ? "is-active" : ""}`}
        id="navMenu"
        style={{
          background: "transparent",
          boxShadow: "0px 4px 14px rgba(0, 0, 0, 0.1)",
        }}
      >
        <div
          className="navbar-end has-text-black is-hidden-desktop"
          style={{ marginLeft: 20 }}
        >
          {/* <a href="/app/login" style={styles.navTitles}>
            <HoverText>Admin</HoverText>
          </a> */}
          {/* <a href="/" style={styles.navTitles}>
            <HoverText>Accueil</HoverText>
          </a>
          <a href="/bnCoachingPage" style={styles.navTitles}>
            <HoverText>Ball'N Coaching</HoverText>
          </a>{" "}
          <a href="/agencePage" style={styles.navTitles}>
            <HoverText>L'agence</HoverText>
          </a>
          <a href="/wikiPage" style={styles.navTitles}>
            <HoverText>Wiki</HoverText>
          </a> */}
          <a href="/" style={styles.navTitles}>
            <HoverText>Accueil</HoverText>
          </a>
          <a href="/#faq" style={styles.navTitles}>
            <HoverText>FAQ</HoverText>
          </a>
          <a href="/blog/" style={styles.navTitles}>
            <HoverText>Blog</HoverText>
          </a>
          <a href="/eventPage" style={{ ...styles.navTitles }}>
            <HoverText>Évènements</HoverText>
          </a>
          <a href="https://ballncoaching.com/" style={{ ...styles.navTitles }}>
            <HoverText>Ball'N Coaching</HoverText>
          </a>
        </div>
      </div>
    </nav>
  );
};

export default NavBar;
